import _stringify from "./stringify";
import _parse from "./parse";
var exports = {};
let stringify = _stringify;
let parse = _parse;
exports = {
  stringify,
  parse
};
export default exports;
const _stringify2 = exports.stringify,
      _parse2 = exports.parse;
export { _stringify2 as stringify, _parse2 as parse };